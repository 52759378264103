/**
 * Take care of all the contact form functionality for the website
 * - Form validation
 * - Form submission
 * @function
 * @param {Array} forms - an array of selectors that require contact form functionality
 */
var Contact = function Contact( forms ) {
  if ( !( this instanceof Contact ) )
  {
    return new Contact( forms );
  }

  if ( forms.length > 0 ) {
    forms.forEach( function kickOffFormSubmit( wrapper, index ) {
      listenToFormSubmit( wrapper );
    });
    return true;
  }

  return false;

  /**
   * Set up form submit listener
   * @function
   * @param {String} form - selector string for the form
   */
  function listenToFormSubmit( form ) {
    var $form = $( form );

    $form.submit(function (e) {
      e.preventDefault()

      const $submitButton = $form.find('[type=submit]')
      $submitButton.prop('disabled', true)
      $submitButton.prop('value', 'Sending...')

      const checkSubmit = $('<input>').attr({ 'type': 'hidden', 'name': 'checksubmit', 'value': '1' }),
          $this = $(this)

      if (validate(this)) {
        $this.append(checkSubmit)

        $.post('/includes/mailer.php', $this.serializeArray()).done((responseData) => {
          if (responseData.result === 'error') {
            showFormResponse('error', "An error happened and the form didn't submit properly. We apologize for the inconvenience. Please try again or contact us via a different method.")
          } else {
            showFormResponse('success', 'Thank you for your interest in AC Horn Manufacturing.<br>A representative will respond to your message shortly.')
          }
        })
      }
    })

    function showFormResponse (messageType, message) {
      $form.remove()
      $('.form-response').addClass(messageType).show().html(message)

      const thankYouForm = (window.location.pathname.indexOf('contact') > -1) ? '/thank-you' : '/thank-you-short-form'
      ga('send', 'pageview', thankYouForm)
    }
  }

  /**
   * Validate form
   * @function
   * @param {Object} form - DOM object selector from the submit callback
   * @return {Boolean} - return form validates
   */
  function validate( form ) {
    var fields = $( form ).find( 'input, select, textarea' ),
        errors = 0;

    $( 'span.error' ).remove();

    fields.each( function( index, el ) {
      var $this = $( this );

      // check required fields
      if ( ( $this.hasClass( 'required' ) || $this.attr( 'required' ) ) && !$this.val() && $this.parent().css( 'display' ) !== 'none' ) {
        setError( $this );
        errors++;
      }

      // check email fields
      if ( $this.hasClass( 'email' ) && !checkEmail( $this.val() ) ) {
        setError( $this, 'email' );
        errors++;
      }
    });

    if (!errors) {
        return true;
    }

    return false;
  }

  /**
   * Test email address for validity
   * @function
   * @param {String} email - email address to validate
   * @return {Boolean} - return email validates
   */
  function checkEmail( email ) {
    // regex from http://www.regular-expressions.info/email.html
    var emailReg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i;

    return emailReg.test( email );
  }

  /**
   * Set errors on field
   * @function
   * @param {Object} field - jQuery selected field
   * @param {String} type - flag for email or country message
   */
  function setError( field, type ) {
    var label = $( 'label[for="' + field.attr( 'name' ) + '"]' ),
        message = ( type === 'email' ) ? 'Please enter a valid email address'
                  : 'Please fill out the required field',
        error = $( '<span>' ).addClass( 'error' ).text( message );

    // make em red
    field.addClass( 'error' );
    label.addClass( 'error' );

    // make sure the user knows why
    field.after( error );
  }
};

