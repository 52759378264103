// On Load actions
$(document).ready(function() {
  var header = $( '.header-image' ),
      home = $( '.home' ),
      news = home.find( '.news' ),
      container = home.find( '.built-with-integrity-spotlight' ),
      slideshow = home.find( 'ul.equipment-slide' ),
      stories = home.find( '.our-stories .body' ),
      applications = $( '.applications' ),
      newsPage = $( '.top.news' ),
      callout = applications.find( '.callout' ),
      contactPage = $( '.contact-us' ),
      contactForm = $( '.contact' );

  // mobile menu handler
  responsiveMenu();

  if ( home.length ) {
    // news alert handler
    newsAlert( news );

    // spotlight handler
    spotlightScroll( container );

    // crop the slideshow text
    slideshow.find( 'p.caption' ).text( hundredChars );

    slideshow.find( 'h3' ).text( function( i, val ) {
      var str = val.split( ' ' );

      if ( str[0] === 'M320' ) {
        str[0] = 'M320-K';

        return str.join( ' ' );
      }

      return val;
    });

    // start the slideshow
    slideshow.cycle({
      pager: '> .controls > .cycle-pager',
      next: '> .controls > .cycle-next',
      prev: '> .controls > .cycle-prev',
    });

    // crop the story text
    stories.find( 'p:not(:first-child)' ).remove();
    stories.find( 'div, img, .video-caption' ).remove();
    stories.find( 'p' ).text( hundredChars );
  }

  if ( applications.length ) {
    // callout text!
    calloutText( callout );

    // make descriptions smaller
    $( '.item .description' ).dotdotdot({
      watch: 'window'
    });
  }

  if ( newsPage.length ) {
    $( '.story .body' ).find( 'div, img, .video-caption' ).remove();
    $( '.story .body' ).find( 'p:not(:first-child)' ).remove();
  }

  // sidebar contact form show handler
  if ( !contactPage.length ) {
    contactForm.find( 'textarea[name="question"]' )
      .on( 'focus', function() {
        var bottom = $( this ).next( '.bottom-half' );

        bottom.show( 'slow' );
      });
  }

  Contact( [contactForm] );
});

// helper function for $.text()
// returns first 100 characters cut off on a word boundary
function hundredChars( i, val ) {
    var hundred = /^(.){100}\w+?[^\b|\n|\t]/gi,
        match = hundred.exec( val );

  return match ? match[0].trim() + '...' : val;
}

function newsAlert( news ) {
  var close = news.find( '.alert .close' ),
      display = news.find( '.alert span' ).css( 'display' );

  news.css( 'visibility', 'visible' );

  if ( viewport().width <= 720 && display !== 'none' ) {
    news.find( '.alert span' ).hide();
  }

  close.on( 'click', function( e ) {
    e.preventDefault();

    news.animate({
      'height': '-=30px'
    }, 600, function() {
      news.css( 'display', 'none' );
    });
  });

  $( window ).on( 'resize', function() {
    var span = news.find( '.alert span' ),
        display = span.css( 'display' );

    if ( viewport().width <= 720 && display !== 'none' ) {
      span.hide();
    }

    if ( viewport().width > 720 && display === 'none' ) {
      span.show();
    }
  });
}

function viewport() {
  var e = window, a = 'inner';
  if (!('innerWidth' in window )) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
}

function spotlightScroll( container ) {
    var spotlight = [
            {
                src: 'spotlight/homepage-image-1.jpg',
                txt: 'AC Horn builds longevity and performance into each piece of equipment',
                link: 'Learn More About Our Equipment'
            },
            {
                src: 'spotlight/homepage-image-2.jpg',
                txt: 'AC Horn equipment is designed for decades of maximum throughput with minimal maintenance',
                link: 'Learn More About Our Equipment'
            },
            {
                src: 'spotlight/homepage-image-3.jpg',
                txt: 'AC Horn has a depth of experience manufacturing and servicing equipment that stretches across almost every type of snack food',
                link: 'Learn More About Our Equipment'
            },
            {
                src: 'spotlight/homepage-roaster.jpg',
                txt: 'Our AC Horn food processing equipment holds up where others fall short because of our family’s unwavering commitment to honest craftsmanship and genuine quality.',
                link: 'Learn More About Our Equipment'
            },
            {
                src: 'spotlight/homepage-hands.jpg',
                txt: 'The AC Horn product line features best-in-class nut roasters and popcorn poppers, plus top-quality equipment for milling, peanut blanching and the packaging room.',
                link: 'Learn More About Our Equipment'
            }
        ],
        words = container.find( 'p' ),
        link = container.find( '.learn-more span' );

    for (var i = 0; i < spotlight.length; i++) {
        container.append('<div class="spotlight-item" style="background-image: url(/assets/images/' + spotlight[i].src + ')">');
    }


    var nextIndex = 1;
    var prevIndex = 1;
    (function spotlightFade() {
        nextIndex++;
        if (nextIndex >= spotlight.length + 1) {
            nextIndex = 1;
        }

        setTimeout( function() {
            container.find('.spotlight-item:nth-of-type('+prevIndex+')').fadeTo(1600, 0);
            container.find('.spotlight-item:nth-of-type('+nextIndex+')').fadeTo(800, 1);

            words.html(spotlight[nextIndex - 1].txt);
            link.html(spotlight[nextIndex - 1].link);

            prevIndex = nextIndex;

            spotlightFade();
        }, 5000);
    })();
}

function responsiveMenu() {
  var anchor = $( '#mobile-nav ul.primary li a' ),
      menu = anchor.next( 'ul.secondary' ),
      overlay = $( '.overlay' );

    const mobileNav = document.getElementById('mobile-nav')

  // Make overlay 100% for responsive menu
  overlay.css({'height': $(document).height()});

  menu.before( '<span class="arrow">' );

  $('.actions > .navicon-button').click(function(e){
      e.preventDefault()
      mobileNav.style.setProperty('display', 'block')
      document.body.style.setProperty('overflow', 'hidden')
      overlay.fadeIn()
      $(mobileNav).animate({
          right: '0',
      }, 500)
  });

  $('#mobile-nav a').click(function(e){
    var $this = $( this ),
        parent = $this.parent(),
        ul = parent.children( 'ul' );

    if ( ul.length > 0 ) {
      e.preventDefault();

      ul.slideToggle( 'slow' );
    }

    $this.next( '.arrow' ).toggleClass( 'active' );
  });

  $('.overlay, #mobile-nav .navicon').click(function(e){
      document.body.style.removeProperty('overflow')
      overlay.fadeOut()
      $(mobileNav).animate({
          right: '-100%',
      }, 500, function () {
          mobileNav.style.setProperty('display', 'none')
          $('#mobile-nav .dropdown').hide()
      })
  });
}

function calloutText( callout ) {
  var text = callout.text(),
      parent = callout.parent(),
      newcall = $( '<div>' );

  newcall.addClass( 'new-callout' ).html( '&ldquo;' + text.charAt( 0 ).toUpperCase() + text.slice( 1 ) + '&rdquo;' );

  parent.after( newcall );
}
